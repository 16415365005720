import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-[20px] absolute right-[12px] top-1/2 ty-center" }
const _hoisted_2 = ["onClick"]

import { ref, reactive } from 'vue'
import { FloatToolList, FloatToolListType } from '../types'

export default /*@__PURE__*/_defineComponent({
  __name: 'FloatTool',
  props: {},
  emits: [
  'copySpite',
  'setIndex',
],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const list = ref<FloatToolList>([
  { iconfont: 'icon-a-15fuzhi', type: 'copy', },
  { iconfont: 'icon-a-10qianmian', type: 'before', },
  { iconfont: 'icon-a-11houmian', type: 'after', },
])
const onClick = (type: FloatToolListType) => {
  switch(type) {
    case 'copy':
      emits('copySpite')
      break
    case 'before':
      emits('setIndex', 'top')
      break
    case 'after':
      emits('setIndex', 'bottom')
      break
  }
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        onClick: ($event: any) => (onClick(item.type)),
        class: "flex bg-[#fff] shadow-md hover:bg-[#ddd] w-[36px] h-[36px] items-center justify-center cursor-pointer rounded-[4px]"
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(`text-[16px] iconfont ${item.iconfont}`)
        }, null, 2)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}
}

})