import { IChildSprite, IImageSprite, ITextSprite } from "@/types"
import { TextStyle } from "pixi.js"
import { getTextFontStyle } from "./core"
import { getSpriteTypeValue } from "./utils"

interface IOption {
  onAdd: (t: IChangeItem) => void;
  onDel: (t: IChangeItem) => void;
  onUpdate: (t: IChangeItem) => void;
  getData: (type: IHistoryItemType, list: any[]) => IHistoryItem;
}
export type IHistoryItemType = 'add' | 'del' | 'update'
type IHistoryItemTarget = IImageSprite[] | ITextSprite[]
interface IRecordDataTemp {
  type: IHistoryItemType;
  changeList: IChangeItem[]
}
export interface IChangeItem extends IChildSprite {
  target: IImageSprite | ITextSprite
}
export interface IHistoryItem {
  type: IHistoryItemType;
  changeList: IChangeItem[]
}
class History {
  // 撤销
  public revokeList: IHistoryItem[] = []
  // 恢复
  public restoreList: IHistoryItem[] = []
  public option: IOption | undefined
  public recordDataTemp: IRecordDataTemp | undefined
  constructor(option: IOption) {
    this.option = option
  }

  // 针对两端记录-开始
  public recordStart(type: IHistoryItemType, list: any[]) {
    const historyItem = this.getRecordData(type, list)
    this.recordDataTemp = this.option?.getData(historyItem.type, historyItem.changeList)
  }
  // 针对两端记录-结束
  public recordEnd() {
    this.recordDataTemp && this.revokeList.push(this.recordDataTemp)
    this.resetRestore()
    console.log(this.revokeList, '--record')

  }

  // 清空恢复
  public resetRestore() {
    this.restoreList = []
  }

  public resetAll() {
    this.restoreList = []
    this.revokeList = []
  }

  // 单条完整记录
  public record(type: IHistoryItemType, list: any[]) {
    const historyItem = this.getRecordData(type, list)
    const item = this.option?.getData(historyItem.type, historyItem.changeList)
    item && this.revokeList.push(item)
    this.resetRestore()
    console.log(this.revokeList, '--record')
  }
  public getRecordData(type: IHistoryItemType, list: any[]) {
    return {
      type,
      changeList: list.map((t: IImageSprite | ITextSprite) => {
        return {
          target: t,
        }
      }),
    }
  }

  public execute(type: IHistoryItemType, item: IHistoryItem) {
    switch (type) {
      case 'add':
        item.changeList.forEach((t) => this.option?.onDel(t))
        break;
        
        case 'del':
        item.changeList.forEach((t) => this.option?.onAdd(t))
        break;
        
        case 'update':
        item.changeList.forEach((t) => this.option?.onUpdate(t))
        break;
    
      default:
        break;
    }
  }

  private formatChangeType(type: IHistoryItemType): IHistoryItemType {
    switch (type) {
      case 'add':
        return 'del'
      case 'del':
        return 'add'
      case 'update':
        return 'update'
    }
  }
  
  public revoke() {
    if (this.revokeList.length === 0) {
      return
    }
    const item = this.revokeList.pop()
    if(item) {
      let restoreItem = this.option?.getData(item.type, item.changeList)
      this.execute(item.type, item)
      restoreItem && this.restoreList.push({
        ...restoreItem,
        type: this.formatChangeType(restoreItem.type)
      })
    }
  }

  public restore() {
    if (this.restoreList.length === 0) {
      return
    }
    const item = this.restoreList.pop()
    
    if(item) {
      let revokeItem = this.option?.getData(item.type, item.changeList)
      this.execute(item.type, item)
      revokeItem && this.revokeList.push({
        ...revokeItem,
        type: this.formatChangeType(revokeItem.type)
      })
    }
  }
}
export default History