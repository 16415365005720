
import * as PIXI from 'pixi.js'
import deleteImg from '@/assets/images/tub-35.png'
import rotateImg from '@/assets/images/tub-36.png'
// import moveImg from '@/assets/images/tub-37.png'
import scaleImg from '@/assets/images/tub-38.png'
import scale_arrowImg from '@/assets/images/tub-39.png'
import iconImg from '@/assets/images/tub-4.png'

/**
 * 为对象创建新的精灵
 * @param {*} obj 目标对象
 * @param {*} key 名称
 * @param {*} Sprite 精灵
 * @param {*} data 属性
 */
function addObjectKey(obj, key, Sprite, data) {
  obj[key] = Sprite
  for(let forKey in data) {
    obj[key][forKey] = data[forKey]
  }
}

function load() {
  const { app } = this
  //图标资源加载
  let loader = app.loader;
  loader.add('delete', deleteImg)
  .add('rotate', rotateImg)
  // .add('move', moveImg)
  .add('scale', scaleImg)
  .add('scale_arrow', scale_arrowImg)
  .add('icon', iconImg)

  this.resources = loader.resources
  const operate_icon = {};
  loader.load(() => {
    
    const container = this.operate_icon_container
    addObjectKey(operate_icon, 'delete', new PIXI.Sprite(this.resources['delete'].texture), {
      name: '删除键',
      ctrl: true
    })

    addObjectKey(operate_icon, 'rotate', new PIXI.Sprite(this.resources['rotate'].texture), {
      name: '旋转键',
      ctrl: true
    })

    // addObjectKey(operate_icon, 'move', new PIXI.Sprite(this.resources['move'].texture), {
    //   name: '移动键',
    //   ctrl: true
    // })
    
    operate_icon.scale = [];
    for (let i = 0; i < 4; i++) {
      operate_icon.scale.push(new PIXI.Sprite(this.resources['scale'].texture));
      operate_icon.scale[i].name = '缩放键' + i;
    }
    addObjectKey(operate_icon, 'scale_arrow', new PIXI.Sprite(this.resources['scale_arrow'].texture), {
      name: '缩放箭头',
      ctrl: true
    })

    operate_icon.line = new PIXI.Graphics();

    container.addChild(operate_icon.line); //框线
    container.addChild(operate_icon.delete);
    container.addChild(operate_icon.rotate);
    // container.addChild(operate_icon.move);
    container.addChild(operate_icon.scale_arrow);
    this.operate_icon = operate_icon

    let that = this
    for (let o of container.children) {
      if (o.isSprite) {
        o.anchor.set(0.5);
        o.width = 33;
        o.height = 33;
      }

      if (![operate_icon.line].includes(o)) {
        o.interactive = true;
        o.on('pointerdown', function (event) {
          that.target = {
            ...that.target,
            name: this.name
          }
        })
      }
    }
  });
}

export default load