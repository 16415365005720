import { tap, usePlatformEventName } from './utils'

function hiddenOtherBtn(name, visible, type) {
  const { operate_icon_container } = this
  operate_icon_container.children.forEach(t => {
    if (visible) {
      if (type === 'label' && t.name === '缩放箭头') {
        return
      }
      t.visible = true
    } else {
      if (t.name !== name) {
        t.visible = false
      }  
    }
  })
}

// 操作按键隐藏逻辑处理
function hiddenBtn(name) {
  switch(name) {
    case '移动键':
    case '缩放箭头':
    case '旋转键':
      hiddenOtherBtn.call(this, name, false)
      break
  }
}

export default function initEvent() {
  const { app } = this
  const that = this
  let appRect;
  let hasTwoTouch = false
  // 是否变化
  let hasChange = false,
    scaleBase = 0,
    stageScale = app.stage.scale.x
  let { startEventName, moveEventName, endEventName } = usePlatformEventName(this.option.type)
  tap(app.view, this.option.type, () => {
    if (!this.eventSelected) {
      this.hideOperateIconContainer()
      this.resetEventTarget()
      this.option?.onCancelSprite()
    } else {
      // this.option?.onSelectSprite(this.target.sprite)
    }
    
  })

  const moveFn = (e) => {
    const { target, operate_icon_container, mobile } = that
    const sprite = that.target.sprite
    if (e.touches?.length === 2) {
      hasChange = true
      if (sprite) {
        let moveScaleBase = Math.sqrt(Math.pow(e.touches[0].pageX - e.touches[1].pageX, 2) + Math.pow(e.touches[0].pageY - e.touches[1].pageY, 2));
        let scale = moveScaleBase / sprite.scaleBase
        let count = Math.abs(sprite.scaleX * scale)
  
        let touches = e.touches
        let p1 = touches[0]
        let p2 = touches[1]
        let startPage = sprite.startPage
        let nowArg = Math.atan2((p1.pageY - p2.pageY), (p1.pageX - p2.pageX))
        let startArg = Math.atan2((startPage[0].pageY - startPage[1].pageY), (startPage[0].pageX - startPage[1].pageX))
        sprite.rotation = nowArg - startArg + sprite.startRotation
  
        if (sprite.type === 'text') {
          sprite.style.fontSize = sprite.startFontSize * scale <= 1 ? 1 : sprite.startFontSize * scale
          sprite.style.lineHeight = sprite.startFontSize * scale <= 1 ? 1 : sprite.startFontSize * scale
        } else {
          
          if (scale * sprite.width_ <= 40) {
            scale = (40 / sprite.width_).toFixed(2) - 0
          }
          sprite.scale.set(
            sprite.scaleX > 0 ? Math.abs(count) : (scale > 0 ? -count : count),
            sprite.scaleY > 0 ? Math.abs(count) : (scale > 0 ? -count : count)
          )
        }
        
        that.showButton(sprite)
        return
      } else {
        // app放大缩小
        let moveScaleBase = Math.sqrt(Math.pow(e.touches[0].pageX - e.touches[1].pageX, 2) + Math.pow(e.touches[0].pageY - e.touches[1].pageY, 2));
        let scale = (moveScaleBase - scaleBase) / scaleBase
        scale *= .4
        let count = Math.abs(stageScale + scale)
        let position = ((count - 1) * (that.option.width / 2))
        app.stage.position.set(
          -(position)
        )
        app.stage.scale.set(count)
      }
    }
    if (target && sprite) {
      let { clientX, clientY } = e.touches ? e.touches[0] : e
      
      // 一旦进入双指将无法进行单指
      if (hasTwoTouch) {
        return
      }
      const name = target.name || sprite.type
      switch(name) {
        case '移动键':
        case 'text':
        case 'image':
          
          hasChange = true
          let app_scale = that.app.stage.scale.x
        
          let x = sprite.startX
          let y = sprite.startY
          let chaX = (clientX - sprite.startX)
          let chaY = (clientY - sprite.startY)
          chaX = chaX / app_scale
          chaY = chaY / app_scale
          // let count =
          if (sprite.startCenterX > sprite.startX) {
            x -= sprite.startX - sprite.startCenterX
            y -= sprite.startY - sprite.startCenterY
          } else {
            x += sprite.startCenterX - sprite.startX
            y += sprite.startCenterY - sprite.startY
          }

          let resultX = x + chaX, resultY = y + chaY, cha = 4
          
          if (resultX > mobile.x - cha && resultX < mobile.x + cha) {
            resultX = mobile.x
          }

          if (resultY > mobile.y - cha && resultY < mobile.y + cha) {
            resultY = mobile.y
          }
          
          sprite.x = resultX
          sprite.y = resultY

          that.showButton(sprite)
          break
        case '缩放箭头':
          hasChange = true
          // let startX = clientX - sprite.startX
          let startX = (clientX - sprite.startX)
          let startY = clientY - sprite.startY
          // console.log(startX, '-startX')
          
          if (!sprite.movePosition) {
            let deg = sprite.rotation * (180 / Math.PI)
            if (deg >= 0 && deg <= 90) {
              sprite.movePosition = 'rightBottom'
            } else if (deg > 90 && deg <= 180) {
              sprite.movePosition = 'leftBottom'
            } else if (deg > 180 && deg <= 270) {
              sprite.movePosition = 'leftTop'
            } else {
              sprite.movePosition = 'rightTop'
            }
          }

          let value = 0
          let valueY = 0
          
          console.log(sprite.movePosition, '--')
          switch(sprite.movePosition) {
            case 'rightTop':
              value = startX / sprite.scaleBtn_currWidth
              valueY = (sprite.startY - clientY) / sprite.scaleBtn_currHeight
              break
            case 'leftTop':
              value = (sprite.startX - clientX) / sprite.scaleBtn_currWidth
              valueY = (sprite.startY - clientY) / sprite.scaleBtn_currHeight
              break
            case 'leftBottom':
              value = (sprite.startX - clientX) / sprite.scaleBtn_currWidth
              valueY = (sprite.startY - clientY) / sprite.scaleBtn_currHeight
              break
            case 'rightBottom':
              value = (startX) / sprite.scaleBtn_currWidth
              // valueY = (startY) / sprite.scaleBtn_currHeight
              break
            // case 'bottom':
            //   valueY = (startY) / sprite.scaleBtn_currHeight
            //   break
            // case 'left':
            //   value = (sprite.startX - clientX) / sprite.scaleBtn_currWidth
            //   break
          }
          value = (sprite.mirrorX ? -(value) : value)
          valueY = (sprite.mirrorX ? -(valueY) : valueY)

          let dataX = sprite.scaleBtn_scaleX + value, dataY = valueY + sprite.scaleBtn_scaleX
          let scale = Math.abs(value) > Math.abs(valueY) ? dataX : dataY
          scale = Math.max(0.05, scale)
          
          if (sprite.type === 'text') {
            sprite.style.fontSize = sprite.startFontSize * scale <= 1 ? 1 : sprite.startFontSize * scale
            sprite.style.lineHeight = sprite.startFontSize * scale <= 1 ? 1 : sprite.startFontSize * scale
          } else {
            sprite.scale.set(
              scale,
              sprite.mirrorX ?  (sprite.mirrorY ? scale : scale * -1) : (sprite.mirrorY ? scale * -1 : scale)
            )
          }
          that.showButton(sprite)
          break
        case '旋转键':
          hasChange = true
          let angle = calculateAngle(sprite.x, sprite.y, clientX - appRect.x, clientY - appRect.y)
          angle -= sprite.startAngle
          let rad = angle * (Math.PI / 180)
          sprite.rotation = rad
          operate_icon_container.rotation = rad
          break
      }
    }
  }
  function calculateAngle(cx, cy, x, y) {
    let angle = Math.atan2(y - cy, x - cx) * (180 / Math.PI);
    // angle = (angle + 52.624192); // 调整角度，加上45度并确保在0到360度之间
    // return angle * (Math.PI / 180); // 将结果转换为弧度
    return angle
  }
  const endFn = (e) => {
    this.eventSelected = false
    const { target } = that
    const sprite = that.target.sprite
    // 
    that.hasEventDown = false
    if (hasTwoTouch && e.targetTouches.length) {
      return
    }
    hasTwoTouch = false
    
    if (sprite) {
      const name = target.name || sprite.type
      switch(name) {
        case '缩放箭头':
          this.resetCountImageSize(sprite, sprite.urlValue)
          break
        case '旋转键':

          break
        case '删除键':
          
          break
      }
      sprite.movePosition = null
      hiddenOtherBtn.call(that, null, true, sprite.type)
      hasChange && that.historyEnd()
    }
    hasChange = false
    app.view.removeEventListener(moveEventName, moveFn)
    app.view.removeEventListener(endEventName, endFn)
  }
  app.view.addEventListener(startEventName, (e) => {
    const { target } = that
    const sprite = target.sprite
    const touches = e.touches
    

    // 多指缩放
    if (e.touches?.length === 2) {
      hasTwoTouch = true
      scaleBase = Math.sqrt(Math.pow(e.touches[0].pageX - e.touches[1].pageX, 2) + Math.pow(e.touches[0].pageY - e.touches[1].pageY, 2))
      if (!sprite) {
        stageScale = app.stage.scale.x
        stagePositionX = app.stage.x
        stagePositionY = app.stage.y
        productWidth = that.productSprite.width
        return
      }
      if (sprite.type === 'text') {
        sprite.startFontSize = sprite.style.fontSize
      }
      sprite.startRotation = sprite.rotation
      sprite.startPage = [
        { pageX: touches[0].pageX, pageY: touches[0].pageY },
        { pageX: touches[1].pageX, pageY: touches[1].pageY },
      ]
      sprite.scaleX = sprite.scale.x
      sprite.scaleY = sprite.scale.y
      sprite.scaleBase = scaleBase
      target.name = '缩放箭头'
      return
    }
    if (target && sprite) {
      // this.option?.onSelectSprite(sprite)
      let { clientX, clientY } = touches ? touches[0] : e
      hiddenBtn.call(that, target.name)
      that.historyStart('update', [sprite])
      
      // 一旦进入双指将无法进行单指
      if (hasTwoTouch) {
        return
      }
      if (!appRect) {
        appRect = app.view.getBoundingClientRect()
      }
      const name = target.name || sprite.type
      switch(name) {
        case '移动键':
        case 'text':
        case 'image':
          sprite.downX = sprite.width - (sprite.x + sprite.width / 2 - clientX)
          sprite.downY = sprite.height - (sprite.y + sprite.height / 2 - clientY)
          sprite.zoomX = clientX - sprite.downX + (sprite.width / 2)
          sprite.zoomY = clientY - sprite.downY + (sprite.height / 2)

          sprite.startX = clientX
          sprite.startY = clientY
          sprite.startCenterX = sprite.x
          sprite.startCenterY = sprite.y
       
          break
        case '缩放箭头':
          this.hasEventDown = true
          sprite.startX = clientX
          sprite.startY = clientY
          if (sprite.type === 'text') {
            sprite.startFontSize = sprite.style.fontSize
          }
          // sprite.clientX
          // sprite.widHeiScale = sprite.originSize.width / sprite.originSize.height
          sprite.scaleBtn_currWidth = sprite.width
          sprite.scaleBtn_currHeight = sprite.height
          sprite.scaleBtn_scaleX = sprite.scale.x
          sprite.scaleBtn_scaleY = sprite.scale.y
          sprite.scaleBtn_baseX = sprite.scale.x / sprite.width
          sprite.scaleBtn_baseY = sprite.scale.y / sprite.height
          sprite.centerPoint = { x: sprite.x, y: sprite.y }
          // 计算图片1:1的宽度
          // (1-0.3)/0.3*50
          sprite.conventionScale = sprite.width + (1 - sprite.scaleBtn_scaleX) / sprite.scaleBtn_scaleX * sprite.width
        
          break
        case '旋转键':
          this.hasEventDown = true
          sprite.rotation = sprite.rotation || 0
          if (!sprite.startAngle) {
            sprite.startAngle = calculateAngle(sprite.x, sprite.y, clientX - appRect.x, clientY - appRect.y)
          }
          sprite.centerPoint = {
            x: (sprite.x * app.stage.scale.x) + 25,
            y: (sprite.y * app.stage.scale.y) + 25
          }
          sprite.realRotation = sprite.rotation
          let rad = Math.atan2((clientY +  + Math.abs(app.stage.y)) - sprite.centerPoint.y, (clientX + Math.abs(app.stage.x)) - sprite.centerPoint.x)
          sprite.lastRad = rad
        
          break
        case '删除键':
          this.historyRecord('del', [sprite])
          sprite.parent.removeChild(sprite)
          this.hideOperateIconContainer()
          this.resetEventTarget()
          break
      }
      app.view.addEventListener(moveEventName, moveFn);
      app.view.addEventListener(endEventName, endFn)
    }
  })
}