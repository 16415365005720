import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "core" }
const _hoisted_2 = {
  key: 0,
  class: "loading"
}

import { ref, reactive, onMounted, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import Pixi from './core'
import { Loading } from 'vant'
// import ProductImage from '@/assets/images/90669de9-db6c-43e3-846f-567a968b974e.png'
// import ProductImage from '@/assets/images/mobile/14 Pro Max-W.png'
// import ShellImage from '@/assets/images/mobile/14 Pro Max-W.png'
// import ShellShowImage from '@/assets/images/mobile/14-W.png'
import Image1 from '@/assets/images/九巴重機_ALL-01-frame-03-01 1.png'
import { Application } from 'pixi.js'
import { prop } from 'vue-class-component'
import { ICoreAppItem } from '@/views/pixi/types'
import { ISizeInfo } from '@/components/CutImage/types'
import { getDesignSingleApi } from '@/api/common'
import { CoreInitOption, IAppConfig } from '../types'
import { AppType } from '@/views/WebPixi/types'

interface ICoreProps {
  coreIndex: number;
  coreData: ICoreAppItem
  isCurrent: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'core',
  props: {
    coreIndex: {},
    coreData: {},
    isCurrent: { type: Boolean }
  },
  emits: ['onSelect', 'onCancel', 'onAdd', 'onDel', 'setHistoryBtn', 'updateTabData'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const route = useRoute()

// let app: any = null
const app = ref<any>(null)
const coreCanvas = ref<any>(null)
const showLoading = ref<boolean>(false)
const isInitCompleted = ref<boolean>(false)
const openShowLoading = () => {
  showLoading.value = true
}
const closeShowLoading = () => {
  showLoading.value = false
}
const onSelectSprite = (app: Application) => {
  emits('onSelect', app)
}
const onCancelSprite = (app: Application) => {
  emits('onCancel', app)
}
const getDesignSingle = async (id: number) => {
  try {
    const result = await getDesignSingleApi(id)
    return result
  } catch(err) {
    console.log(err)
  }
}
const init = async (
  { width, height, productImage, shellImage, designImage }: IAppConfig,
  design: any,
  type: AppType = 'mobile',
  { initialImageWidth, multiple, expandParams }: CoreInitOption
) => {
  if (app.value) {
    try {
      app.value.app.destroy(true)
    } catch(err) {
      console.log(err)
    }
  }
  isInitCompleted.value = true
  app.value = new Pixi(coreCanvas.value, {
    width,
    height,
    type,
    initialImageWidth,
    multiple,
    openLoading: openShowLoading,
    closeLoading: closeShowLoading,
    expandParams,
    onSelectSprite: () => onSelectSprite(app.value),
    onCancelSprite: () => onCancelSprite(app.value),
    onClick: handleClick,
    onAdd: () => {
      emits('onAdd')
    },
    onDel: () => {
      emits('onDel')
    },
    onHistoryChange: () => {
      emits('setHistoryBtn', app.value.history.revokeList.length === 0, app.value.history.restoreList.length === 0)
    },
    // onHistoryRecord: () => {
    //   emits('setHistoryBtn', app.value.history.revokeList.length === 0, app.value.history.restoreList.length === 0)
    // },
    onHistoryBack: () => {
      emits('updateTabData')
    },
    onHistoryForward: () => {
      emits('updateTabData')
    },
  })

  await app.value.addProductImage(productImage)
  await app.value.addShellImage(shellImage || productImage, designImage)

  design && app.value.loadDesign(design)
  
}
const handleClick = () => {
  // @ts-ignore
  document.querySelector('#textInput input')?.blur()
}

__expose({
  app,
  init,
  index: props.coreIndex,
  isInitCompleted
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "core-canvas",
      ref_key: "coreCanvas",
      ref: coreCanvas
    }, null, 512),
    (showLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_unref(Loading), {
            color: "#000000",
            size: "36"
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})