import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-y-[30px]" }
const _hoisted_2 = { class: "text-center text-[18px] font-bold" }
const _hoisted_3 = { class: "flex flex-wrap gap-x-[4%] gap-y-[12px] mx-[12px] mt-[12px]" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]

import { getIntegrateUrl } from '@/views/Pixi/components/utils'
import { ref, reactive } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'SbujectCard',
  props: {
  list: {
    type: Array,
    default() {
      return []
    }
  },
  title: String
},
  emits: ['click'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const onClick = (item: any) => {
  emits('click', item.urlvalue)
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(item.name), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (s, si) => {
            return (_openBlock(), _createElementBlock("div", {
              key: si,
              onClick: () => onClick(s, si),
              class: "flex items-center justify-center w-[22%] h-[90px] hover:opacity-[.9] cursor-pointer"
            }, [
              _createElementVNode("img", {
                src: _unref(getIntegrateUrl)(s.urlvalue, `tplv-z8ialgywaa-w-size:300`),
                alt: "",
                class: "max-w-full max-h-full"
              }, null, 8, _hoisted_5)
            ], 8, _hoisted_4))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}
}

})