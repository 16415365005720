import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center w-full" }

import { ref, reactive } from 'vue'
import {
  RadioGroup,
  RadioButton
} from 'ant-design-vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'Underside',
  props: {
  list: {
    type: Array,
    default() {
      return []
    }
  },
},
  emits: ['switchSide'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const value = ref(0)
const onClick = (index: number) => {
  emits('switchSide', index)
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(RadioGroup), {
      value: value.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
      onChange: _cache[1] || (_cache[1] = (e) => onClick(e.target.value))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
          return (_openBlock(), _createBlock(_unref(RadioButton), {
            key: index,
            value: index
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.sideName), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}
}

})