import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex items-center justify-center flex-col cursor-pointer hover:bg-[#eee]",
  style: {"border":"1px solid #eee","border-radius":"4px","height":"70px","width":"70px"}
}

import { ref, reactive } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  title: String,
  iconfont: String,
},
  emits: [],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      class: _normalizeClass(`iconfont ${__props.iconfont}`)
    }, null, 2),
    _createElementVNode("p", null, _toDisplayString(__props.title), 1)
  ]))
}
}

})