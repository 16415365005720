import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 2 }

import { ref, reactive, watch } from 'vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import {
  Upload as AUpload,
  message,
} from 'ant-design-vue';
import { UploadProps } from 'ant-design-vue/es/vc-upload/interface';
import { uploadImage, uploadResourece } from '@/api/common';
import { addFile } from '../update';
import { uploadImageToStrapi } from '@/views/Pixi/components/utils';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  size: {
    type: Number,
    default: 102
  },
  list: {
    type: Array || Object,
  },
  hasUpdate: {
    type: Boolean,
    default: false
  },
  value: {
    type: String,
  },
},
  emits: ['change', 'fail'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const route = useRoute();
const imageUrl = ref<string>('')
const loading = ref(false)
const beforeUpload = () => {}
const handleChange = () => {}
const customRequest = ({ file }: { file: File }) => {
  var reader = new FileReader();
  loading.value = true
  // 定义文件读取完成时的回调函数
  reader.onload = async () => {
    if (typeof reader.result !== 'string') {
      loading.value = false
      return
    }
    // 获取Base64编码的字符串
    var base64 = reader.result
    
    try {
      const url = await addFile(base64) as string
      let userId = localStorage.getItem('USER_ID')
      if (!userId) {
        console.error('user id is null')
        return
      }
      if (props.hasUpdate) {
        console.log('aaaaa', url)
        const res = await uploadImageToStrapi(url, file.name)
        await uploadResourece({
          id: res?.[0].id as number,
          user_id: Number(userId),
          url,
          app_id: `${route.query.app_id || ''}`,
        })
      }
      emits('change', url)
      loading.value = false
    } catch(err) {
      console.log(err)
      emits('fail', err)
      loading.value = false
      message.error('上传图片失败')
    }
  };
  
  // 读取文件内容并将结果作为Data URL
  reader.readAsDataURL(file);
  console.log(file)
}

watch(() => props.value, (url) => {
  imageUrl.value = url as string
}, { immediate: true })

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AUpload), {
    name: "avatar",
    "list-type": "picture-card",
    class: "avatar-uploader",
    "show-upload-list": false,
    customRequest: customRequest,
    style: _normalizeStyle({
      wdith: __props.size + 'px',
      height: __props.size + 'px',
    })
  }, {
    default: _withCtx(() => [
      (loading.value)
        ? (_openBlock(), _createBlock(_unref(LoadingOutlined), { key: 0 }))
        : (imageUrl.value)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: imageUrl.value,
              alt: "avatar"
            }, null, 8, _hoisted_1))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_unref(PlusOutlined)),
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "ant-upload-text" }, "Upload", -1))
            ]))
    ]),
    _: 1
  }, 8, ["style"]))
}
}

})