import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-[14px] text-[#999]" }
const _hoisted_2 = { class: "flex flex-wrap gap-[10px]" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]

import { ref, reactive, ComponentObjectPropsOptions } from 'vue'
import { useI18n } from 'vue-i18n'

type Options = {
  label: string;
  value: string;
}[]
type Props = {
  options: Options;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  options: {
    type: Array,
    default() {
      return []
    }
  },
  max: Number,
},
  emits: ['update:value'],
  setup(__props, { expose: __expose, emit: __emit }) {

const { t } = useI18n()
const props = __props
const emits = __emit

const current = ref([])
const onItemClick = (value: string) => {
  let findIndex = current.value.findIndex(t => t === value)
  if (findIndex === -1) {
    if (props.max && current.value.length >= props.max) {
      current.value.splice(0, 1)
    }
    current.value.push(value)
  } else {
    current.value.splice(findIndex, 1)
  }
  emits('update:value', current.value)
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)('selected_preview_before')) + _toDisplayString(current.value.length) + _toDisplayString(_unref(t)('selected_preview_after')), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["flex-1 border p-[10px]", {
        'border-[#1677ff]': current.value.includes(item.value),
      }]),
          key: item.value,
          onClick: () => onItemClick(item.value)
        }, [
          _createElementVNode("img", {
            src: item.value,
            alt: ""
          }, null, 8, _hoisted_4)
        ], 10, _hoisted_3))
      }), 128))
    ])
  ]))
}
}

})