import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header flex h-full items-center pr-2" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { class: "flex-auto flex justify-end gap-[20px]" }

import { ref, reactive, h } from 'vue'
import { ArrowLeftOutlined, ArrowRightOutlined, RedoOutlined, CheckOutlined } from '@ant-design/icons-vue';
import LogoImage from '@/assets/images/logo-web.png'
import {
  Button,
} from 'ant-design-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  props: {
  text: String
},
  emits: [
  'submit',
  'historyBack',
  'historyForward',
  'reset',
],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "w-[180px] flex justify-center" }, null, -1)),
    _createElementVNode("div", _hoisted_2, _toDisplayString(__props.text), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_unref(Button), {
        icon: h(_unref(ArrowLeftOutlined)),
        onClick: _cache[0] || (_cache[0] = () => emits('historyBack'))
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("撤銷")
        ])),
        _: 1
      }, 8, ["icon"]),
      _createVNode(_unref(Button), {
        icon: h(_unref(ArrowRightOutlined)),
        onClick: _cache[1] || (_cache[1] = () => emits('historyForward'))
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("恢復")
        ])),
        _: 1
      }, 8, ["icon"]),
      _createVNode(_unref(Button), {
        icon: h(_unref(RedoOutlined)),
        onClick: _cache[2] || (_cache[2] = () => emits('reset'))
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode("重置")
        ])),
        _: 1
      }, 8, ["icon"]),
      _createVNode(_unref(Button), {
        icon: h(_unref(CheckOutlined)),
        type: "primary",
        onClick: _cache[3] || (_cache[3] = () => emits('submit'))
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode("提交")
        ])),
        _: 1
      }, 8, ["icon"])
    ])
  ]))
}
}

})